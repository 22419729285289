import React from "react";

class Link extends React.Component {
    render = () => {
        return (
            <a rel={this.props.rel} href={this.props.href} className="App-item link ">
                <img
                    className={"link-img" + (this.props.invert === "true" ? " link-invert" : "")}
                    src={this.props.img}
                    alt={this.props.name}
                    {...this.props.imgProps}
                />

                <span>{this.props.name}</span>
            </a>
        );
    };
}

export default Link;
