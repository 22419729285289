import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify"; // Importing react-toastify
//import "react-toastify/dist/ReactToastify.css"; // Toastify CSS
import "./ToastStyles.css"; // Custom Toastify CSS

const ImageUpload = ({ cloudName, uploadPreset }) => {
    const [fileInputState, setFileInputState] = useState("");

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", uploadPreset);

        fetch(`https://api.cloudinary.com/v1_1/${cloudName}/upload`, {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                toast.success("Upload successful!");
                setFileInputState(""); // Reset the file input
                // Additional handling for the image URL
            })
            .catch((error) => {
                toast.error("Upload failed!");
                console.error("Upload error:", error);
            });
    };

    return (
        <div>
            <label htmlFor="fileUpload" className="customFileUpload">
                Choose File
            </label>
            <input
                id="fileUpload"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                value={fileInputState} // Reset the input field after upload
                style={{ display: "none" }} // Hide the default file input
            />
            <ToastContainer />
        </div>
    );
};

export default ImageUpload;
