import React, { useEffect } from "react";
import "./App.scss";

import Link from "./Components/Link";
import ImageUpload from "./Components/ImageUpload"; // Importing ImageUpload component

import Typewriter from "typewriter-effect";
import "react-toastify/dist/ReactToastify.css";

import critterBadge from "./critterbadge3.png";

import { rainbowCursor } from "cursor-effects";
new rainbowCursor({
    length: 30,
    colors: ["red", "orange", "yellow", "green", "blue", "purple"],
    size: 2,
});

function App() {
    const [heart, setHeart] = React.useState(0);
    const [clickCount, setClickCount] = React.useState(0);
    const [clicked, setClicked] = React.useState(0);

    const cloudName = "df54oudst";
    const uploadPreset = "conventions";

    var links = [];
    var clickedLinks = [];

    var bkgrndSpans = [];
    for (var i = 0; i < 36; i++) {
        bkgrndSpans.push(<span />);
    }

    useEffect(() => {
        const lock = document.createElement("meta");
        lock.name = "darkreader-lock";
        document.head.appendChild(lock);
    }, []);

    return (
        <div className="App">
            <div className="App-background">{bkgrndSpans}</div>
            <header className="App-header">
                <div className="App-item">
                    <p className="header-title">
                        <img src={critterBadge} alt="Critter Badge" width="250px"></img>
                        <h1>Tidaleus</h1>
                        <h4>| tide—uh—lus |</h4>
                        <h2>⟦ 23 ⟧</h2>
                        <h2>⟦ He/Him ⟧</h2>
                        <h2>⟦ Manokit ⟧</h2>
                    </p>

                    <div className="header-typewriter">
                        <Typewriter
                            options={{
                                strings: [
                                    "Blue Barkshark",
                                    "Manokult Member",
                                    "SpiritPanda Suiter",
                                    "Sticker Enthusiast",
                                    "College Student",
                                    "Professional Lurker",
                                    "Digital Dorsal",
                                    "Shark-Byte Scholar",
                                    "Circuit Surfer",
                                    "Manokit Maverick",
                                    "Bytes and Bites",
                                    "Fish are Friends, not Food",
                                    "Shark Byte",
                                    "Got that dog in me 🐕",
                                    "Not related to that guy from Jaws",
                                ],
                                autoStart: true,
                                loop: true,
                            }}
                        />
                    </div>
                </div>
            </header>
            <body className="App-body animate-section">
                <hr />

                <h2 className="white-text">Met me at a Con? Upload any pictures of Tidal here!</h2>
                <ImageUpload cloudName={cloudName} uploadPreset={uploadPreset} />
                <hr />
                <div className="links">
                    <Link
                        name="Twitter"
                        img="https://upload.wikimedia.org/wikipedia/commons/6/6f/Logo_of_Twitter.svg"
                        href="https://twitter.com/tidaleus"
                    />
                    <Link
                        name="Bluesky"
                        img="https://raw.githubusercontent.com/twitter/twemoji/master/assets/svg/1f30c.svg"
                        href="https://bsky.app/profile/tidale.us"
                    />
                    <Link
                        name="FurAffinity"
                        img="https://user-images.githubusercontent.com/73164889/117507463-efde5e80-af7e-11eb-8886-5aeb3ce43778.png"
                        href="https://furaffinity.net/user/tidaleus"
                    />
                    <Link
                        name="Telegram"
                        img="https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg"
                        href="https://t.me/Tidaleus"
                    />
                    <Link
                        name="Furtrack"
                        img="https://www.furtrack.com/favicon.png"
                        href="https://www.furtrack.com/user/Tidaleus/fursuiting"
                    />
                    <Link
                        name="GitHub"
                        img="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg"
                        href="https://github.com/manokit"
                        invert="true"
                    />
                </div>

                {clicked === 1 ? <div className="links clicked-links">{clickedLinks}</div> : <div />}
            </body>

            <hr />

            <footer className="App-footer">
                <img
                    className="footer-img"
                    src="https://raw.githubusercontent.com/twitter/twemoji/master/assets/svg/2764.svg"
                    alt="❤"
                    width="150px"
                    onClick={() => {
                        setHeart(1);
                        setClickCount(clickCount + 1);
                    }}
                    onAnimationEnd={() => setHeart(0)}
                    heart={heart}
                />
                {clickCount > 0 && clickCount < 10 && <p className="white-text">clicked {clickCount} times</p>}
                {clickCount >= 10 && clickCount <= 49 && (
                    <p className="white-text">You must love it here, clicked {clickCount} times</p>
                )}
                {clickCount >= 50 && clickCount <= 74 && (
                    <p className="white-text">Why are you still here? clicked {clickCount} times</p>
                )}
                {clickCount >= 75 && clickCount <= 99 && (
                    <p className="white-text">I think you need some more hobbies.. clicked {clickCount} times</p>
                )}
                {clickCount >= 100 && clickCount <= 104 && <p className="white-text">Ok clicked {clickCount} times</p>}
                {clickCount >= 105 && clickCount <= 109 && <p className="white-text">Ok. clicked {clickCount} times</p>}
                {clickCount >= 110 && clickCount <= 114 && <p className="white-text">Ok.. clicked {clickCount} times</p>}
                {clickCount >= 115 && clickCount <= 119 && <p className="white-text">Ok... clicked {clickCount} times</p>}
                {clickCount >= 120 && <p className="white-text">I'm going now.. good luck.. clicked {clickCount} times</p>}
            </footer>

            <hr />
        </div>
    );
}

export default App;
